// Shared injection keys used for dependency injection between parent/child components or pages
// See: https://vuejs.org/api/composition-api-dependency-injection.html
// and: https://vuejs.org/guide/typescript/composition-api.html#typing-provide-inject

import type { ShallowRef } from "vue";

export interface SearchTabsContext {
    tab: Readonly<Ref<string | number | undefined>>;
    setTab: (value: string | number) => void;
}

export const SearchInjectKey: InjectionKey<SearchTabsContext> = Symbol("SearchTabs");

export interface SearchIds {
    distributorIds: ShallowRef<Set<number>>;
    manufacturerIds: ShallowRef<Set<number>>;
    partKeys: ShallowRef<Set<string>>;
    sortOrders: ShallowRef<Set<string>>;
    clickHash: Ref<string>;
}

export const SearchIdsInjectKey: InjectionKey<SearchIds> = Symbol("SearchIds");

export interface BOMContext {
    currentBOM: Readonly<Ref<BOMSearch | undefined>>;
}

export const BOMInjectKey: InjectionKey<BOMContext> = Symbol("BOM");
